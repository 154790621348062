.dropdown-container {
    position: relative;
    display: inline-block;
    text-align: left;
}

.dropdown-button {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #4A5568;
    background-color: #FFFFFF;
    border: 1px solid #D2D6DC;
    border-radius: 0.375rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    outline: none;
}

.dropdown-icon {
    margin-left: 0.5rem;
    height: 1.25rem;
    width: 1.25rem;
}

.dropdown-menu {
    position: absolute;
    left: 0;
    margin-top: 0.5rem;
    width: 14rem;
    border-radius: 0.375rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.05);
    z-index: 10;
    outline: none;
}

.menu {
    padding: 0.5rem 0;
}

.menu-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: #4A5568;
    cursor: pointer;
}

.checkbox {
    margin-right: 0.5rem;
}
